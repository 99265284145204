<template>
    <div>
        <CCard>
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="categoryFields" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { createNewCategory, getCategoriesData } from '@/services/categories';
import { getCategoriesTypeData } from '@/services/category_type';
import AbstractForm from '@/components/Forms/AbstractForm.vue';
import { getFormAddCategory } from "@/utils/forms/categories/form";

export default {
    name: "addCategories",
    components: { AbstractForm },
    data(){
      return{
        title: "Añadir una nueva categoría",
        id: null,
        categories: [],
        categoryFields: {
            name: null,
            psy_name: null,
            type: null
        },
        formContent: getFormAddCategory()
      }
    },
    created() {
        this.getCategoriesType();
        this.getCategories();
     },
    methods: {
        onEmitMethods(event, dataCategory) {
            switch (event) {
                case 'add': this.addCategory(dataCategory); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        /**
         * Método para obtener los tipos de categoría
         */
        getCategoriesType(){
            let data = getCategoriesTypeData();
            data.catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() };})
            .then((result) => {
                const options = result.map(category => {
                    return {
                        name: category.name,
                        value: category.id
                    };
                });
                this.formContent.rows[0].content[2].content.options = options;
            })
        },
        /**
         * Función en la que se obtienen las categorías
         *
         * @returns {object[]} Las categorías obtenidas.
         */
        getCategories() {
        getCategoriesData()
            .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString()};})
            .then((result) => {
                this.categories = result;
            });
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        /**
         * Método para validar los inputs del formulario
         */
        checkInputs(dataCategory) {
            const specialCharactersRegex = /^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s]+$/;
            const validationRules = [
                { name: 'Nombre', field: 'name', message: 'Introduzca el nombre (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Nombre', field: 'name', message: 'El nombre no debe contener caracteres especiales.', regex: specialCharactersRegex },
                { name: 'Tipo de categoría', field: 'type', message: 'Seleccione un tipo de categoría.', value: '' },
                { name: 'Nombre técnico', field: 'psy_name', message: 'Introduzca el nombre técnico (máximo de 255 caracteres).', maxLength: 255 },
                { name: 'Nombre técnico', field: 'psy_name', message: 'El nombre técnico no debe contener caracteres especiales.', regex: specialCharactersRegex },
            ];

            const existingCategory = this.categories.find(category => {
                return category.name.toLowerCase() === dataCategory.name.toLowerCase()
            });
            
            if (existingCategory) {
                this.showAlertError(`El nombre "${dataCategory.name}" ya existe en las categorías.`);
                return false;
            }

            for (const rule of validationRules) {
                const value = dataCategory[rule.field];
                if (value === undefined || value === null) {
                    this.showAlertError(`El campo ${rule.name} está vacío`);
                    return false;
                }
                if(rule.maxLength && value.length > rule.maxLength){
                    this.showAlertError(`El número de caracteres de ${rule.name} debe ser menor o igual al siguiente valor: ${rule.maxLength}`);
                    return false;
                }
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataCategory[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        /**
         * Método para poder crear una nueva categoría
         */
        addCategory(dataCategory) {
            if (this.checkInputs(dataCategory) == false) return;
            createNewCategory(dataCategory)
                .then(() => {
                    this.$router.push("/categories");
                }).catch((error) => {
                    this.$store.state.errorAlert = { status: true, msg: error.toString() } 
                })
        },
        /**
         * Función para mostrar el alert durante 10 segundos.
         */
        showFailureParameters() {
            this.contAlertParams = 10;
        },
        /**
         * Función para volver atrás
        */
        back() {
            this.$router.push("/categories");
        },
    },
};
</script>